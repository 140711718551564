import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'semantic-ui-react';

const PriceRangeIndications = ({ item }) => {
  return item.price_range_indications?.length > 0 ? (
    <Label tag color="red" className="price-range-indications">
      {item.price_range_indications}
    </Label>
  ) : (
    <></>
  );
};

PriceRangeIndications.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PriceRangeIndications;
