import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {
  CheckboxWidget,
  ObjectListWidget,
  FormFieldWrapper,
} from '@plone/volto/components';

import ImageSizeWidget from '@plone/volto/components/manage/Widgets/ImageSizeWidget';

const messages = defineMessages({
  size: {
    id: 'Size',
    defaultMessage: 'Dimensione',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Immagine',
  },
  no_opacity: { id: 'No opacity', defaultMessage: "Non opacizzare l'immagine" },
  buttonText: {
    id: 'SliderBlock: Testo sul bottone',
    defaultMessage: 'Testo sul bottone',
  },
  buttonTextDescription: {
    id: 'SliderBlock: Testo sul bottone description',
    defaultMessage:
      "Se presente, verrà mostrato un bottone che al click porterà alla destinazione scelta nel campo 'Destinazione'. Se non compilato, il link verrà posizionato sul titolo della slide",
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, block, onChangeBlock } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="SliderBlock" defaultMessage="Blocco Slider" />:
        </h2>
      </header>

      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="leadimage"
          title="Mostra nella testata della pagina"
          value={data.leadimage ? data.leadimage : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <CheckboxWidget
          id="autoplay"
          title="Autoplay"
          value={data.autoplay ? data.autoplay : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <CheckboxWidget
          id="fullwidth"
          title="A tutta larghezza"
          value={data.fullwidth ? data.fullwidth : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />

        <FormFieldWrapper id="size" title={intl.formatMessage(messages.size)}>
          <ImageSizeWidget
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
            value={data.size}
            id="size"
          />
        </FormFieldWrapper>
      </Segment>
      <ObjectListWidget
        {...props}
        id="slides"
        value={data?.slides ?? []}
        onChange={(id, value) =>
          props.onChangeBlock(block, { ...data, [id]: value })
        }
        schema={() => {
          return {
            title: 'Slide',
            addMessage: 'Aggiungi una Slide',
            fieldsets: [
              {
                id: 'default',
                title: 'Default',
                fields: [
                  'title',
                  'description',
                  'image',
                  'no_opacity',
                  'href',
                  'href_title',
                ],
              },
            ],
            properties: {
              title: {
                title: intl.formatMessage(messages.title),
              },
              description: {
                title: intl.formatMessage(messages.description),
              },
              image: {
                title: intl.formatMessage(messages.image),
                widget: 'object_browser',
                mode: 'image',
                allowExternals: true,
              },
              no_opacity: {
                title: intl.formatMessage(messages.no_opacity),
                type: 'boolean',
              },
              href: {
                title: 'Link',
                widget: 'object_browser',
                mode: 'link',
                allowExternals: true,
              },
              href_title: {
                title: intl.formatMessage(messages.buttonText),
                description: intl.formatMessage(messages.buttonTextDescription),
              },
            },

            required: [],
          };
        }}
      />
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  onChangeSubblocks: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default Sidebar;
