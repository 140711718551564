/**
 * Add your config changes here.
 * @module config
 */

import GlobalAppExtras from '@package/components/GlobalAppExtras/GlobalAppExtras';
import Favicon from '@package/components/Favicon/Favicon';
import { ScrollToTop } from '@package/components';
import { VisitDraftJSConfig } from '@package/config/draftJS';

import { VisitBlocks } from '@package/config/blocks';
import { VisitWidgets } from '@package/config/widgets';
import { VisitViews } from '@package/config/views';
import { VisitGDPRPrivacy } from '@package/config/gdprPrivacy';
import '@plone/volto/config';

export default function applyConfig(config) {
  // Add here your project config

  /* *** SETTINGS *** */
  config.settings = {
    ...config.settings,
    bbb_getContentFetchesFullobjects: true,
    //legacyTraverse: true,
    showTags: false,
    persistentReducers: [...config.settings.persistentReducers, 'whishlist'],
    imageObjects: [
      'Image',
      'Document',
      'Esperienza',
      'Event',
      'Itinerario',
      'Notizia',
      'SchedaTematica',
    ],

    isMultilingual: true, //true
    supportedLanguages: ['it', 'en'], //['it', 'en'],
    defaultLanguage: 'it',
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: GlobalAppExtras,
      },
      {
        match: '',
        component: Favicon,
      },
      {
        match: '',
        component: ScrollToTop,
      },
    ],
  };

  /* *** DraftJS *** */
  VisitDraftJSConfig(config);

  /* *** VIEWS *** */
  VisitViews(config);

  /* *** WIDGETS *** */
  VisitWidgets(config);

  /* *** BLOCKS *** */
  VisitBlocks(config);

  /* *** gdpr privacy *** */
  VisitGDPRPrivacy(config);

  return config;
}
