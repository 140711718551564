import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from 'semantic-ui-react';
import { ItemTemplates, DefaultItem, LinkMore } from '@package/components';

const EmphasisTemplate = (data) => {
  const { items, isEditMode } = data;

  return items.length > 0 ? (
    <div className="emphasis-template">
      <Grid stackable verticalAlign="top">
        {items.map((item, index) => {
          let ItemTemplate = ItemTemplates[item['@type']] || DefaultItem;

          return (
            <Grid.Column key={item['@id']} width={index < 3 ? 4 : 3}>
              <ItemTemplate
                item={item}
                isEditMode={isEditMode}
                withTags={true}
                infoInTitle={true}
              />
            </Grid.Column>
          );
        })}
      </Grid>

      <LinkMore {...data} />
    </div>
  ) : null;
};

EmphasisTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default EmphasisTemplate;
