export const VisitGDPRPrivacy = (config) => {
  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2024-05-06T09:00:00+00:00',
      text: {
        it: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it,
          description:
            "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente per fini statistici. I cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Cambia le impostazioni'. Per saperne di più su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='/it/privacy' target='_blank'>Cookie Policy.</a>",
        },
        en: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it,
          description:
            "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical and remarketing purposes. For profiling cookies you can decide whether to enable them or not by clicking on the 'Change settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/en/privacy' target='_blank'>Cookie Policy</a>.",
        },
      },
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical
            .choices,
          {
            config_key: 'TREKSOFT',
            text: {
              it: {
                title: 'Trekksoft',
                description:
                  'I cookie di trekksoft sono usati al fine di fornire all’utente un’esperienza di navigazione migliore possibile senza alcun tracciamento',
              },
              en: {
                title: 'Trekksoft',
                description:
                  'The cookies from Trekksoft are used in order to provide the user with the best possible browsing experience without any tracking.',
              },
            },
          },
        ],
      },

      profiling: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling,
        choices: [
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling
            .choices,
          {
            config_key: 'MARKETING_GADS_FBPIXEL_MICROSOFT',
            referenceUrls: ['MARKETING_GADS_FBPIXEL_MICROSOFT'],
            text: {
              it: {
                title: 'Marketing',
                description:
                  "<strong>Google ADS:</strong><br/> Google usa i cookie per la pubblicità, ad esempio per pubblicare e visualizzare annunci, personalizzare gli annunci (in base alle Impostazioni annunci configurate all'indirizzo <a href='https://g.co/adsettings' target='_blank'>g.co/adsettings</a>), limitare il numero di visualizzazioni di un annuncio per un utente, disattivare gli annunci che l'utente ha deciso di non ricevere più e valutare l'efficacia degli annunci.<br/><br/>" +
                  '<strong>Facebook Pixel:</strong><br/> Facebook utilizza i cookie per la pubblicità per mostrare annunci e annunci corrispondenti ai tuoi interessi. Puoi aggiornare le tue preferenze sugli annunci di Facebook <a href="https://www.facebook.com/help/568137493302217" taget="_blank">qui</a>.<br/><br/>' +
                  '<strong>Microsoft:</strong><br/> Microsoft utilizza i cookie per la pubblicità per mostre contenuti mirati in base agli interessi e alle tipologie di utenti.',
              },
              en: {
                title: 'Marketing',
                description:
                  '<strong> Google ADS: </strong> <br/> Google uses cookies for advertising, for example to serve and display ads, personalize ads (based on the Ad Settings configured at <a href = "https://g.co/adsettings" target="_blank">g.co/adsettings</a>), limit the number of views of an ad for a user, disable the ads that the user has decided not to receive anymore and evaluate the effectiveness of the ads. <br/> <br/> ' +
                  '<strong> Facebook Pixel: </strong> <br/> Facebook uses advertising cookies to show ads and ads corresponding to your interests. You can update your Facebook ad preferences <a href="https://www.facebook.com/help/568137493302217" taget="_blank"> here </a>. <br/><br/>' +
                  '<strong> Microsoft: </strong> <br/> Microsoft uses cookies for advertising for targeted contents to the interests and types of users.',
              },
            },
          },
          {
            config_key: 'LIVECHAT',
            referenceUrls: ['LIVECHAT'],
            text: {
              it: {
                title: 'Live chat',
                description:
                  "Su questo sito web è installato un widget LiveChat, un servizio che permette agli utenti di contattarci per richiedere informazioni e supporto. LiveChat salva e memorizza automaticamente alcuni cookies sul tuo dispositivo con le seguenti finalità: verificare l'identità di un cliente creato in LiveChat; verificare il token del cliente. Questi cookie non memorizzano alcuna informazione personale che consenta a qualcuno di identificarti. Altre informazioni funzionali utilizzate da Livechat sono informazioni statistiche che consentono di determinare quante volte hai consultato la pagina. Per maggiori informazioni: <a href = 'https://www.livechat.com/legal/cookies-policy/' target='_blank'>https://www.livechat.com/legal/cookies-policy/<a/>",
              },
              en: {
                title: 'Marketing',
                description:
                  'LiveChat widget is installed on this website, a service that allows users to contact us to request information and support. LiveChat automatically saves and stores some cookies on your device for the following purposes: verifying the identity of a customer created in LiveChat; verify customer token. These cookies do not store any personal information that would allow anyone to identify you. Other functional information used by Livechat is statistical information that makes it possible to determine how many times you have consulted the page. For more information: <a href = "https://www.livechat.com/legal/cookies-policy/" target="_blank">https://www.livechat.com/legal/cookies-policy/<a/>',
              },
            },
          },
        ],
      },
    },
  };
};
