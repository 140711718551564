import React from 'react';
import { Helmet } from '@plone/volto/helpers';

const Favicon = () => (
  <Helmet>
    <link rel="icon" href="/favicon-16x16.png" sizes="16x16" />
    <link rel="icon" href="/favicon-32x32.png" sizes="32x32" />
    <link rel="icon" href="/android-chrome-192x192.png" sizes="192x192" />
  </Helmet>
);

export default Favicon;
