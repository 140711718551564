import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Toast } from '@plone/volto/components';
import { emailNotification } from '@plone/volto/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

const messages = defineMessages({
  requiredField: {
    id: 'Required field',
    defaultMessage: 'This is a required field.',
  },
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  contactForm: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  name: {
    id: 'Name and surname',
    defaultMessage: 'Name and surname',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  emailAddress: {
    id: 'Email address',
    defaultMessage: 'Email address',
  },
  subject: {
    id: 'Subject',
    defaultMessage: 'Subject',
  },
  subjectPlaceholder: {
    id: 'How can we help you?',
    defaultMessage: 'How can we help you?',
  },
  city: {
    id: 'City',
    defaultMessage: 'City',
  },
  optional: {
    id: 'optional',
    defaultMessage: 'optional',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  privacy: {
    id: 'I authorize the processing of personal data',
    defaultMessage: 'I authorize the processing of personal data',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  messageSent: {
    id: 'Email sent',
    defaultMessage: 'Email sent',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  emailNotValid: {
    id: 'emailNotValid',
    defaultMessage: 'E-mail non valida',
  },
});

/**
 * View contactform block class.
 * @class View
 * @extends Component
 */
const View = () => {
  const intl = useIntl();
  const [formValues, setFormValues] = useState({});

  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const submitResults = useSelector((state) => state.emailNotification);
  const dispatch = useDispatch();

  const requiredFields = ['subject', 'name', 'from', 'message'];

  useEffect(() => {
    if (submitResults?.loaded) {
      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.messageSent)}
        />,
      );
    } else if (submitResults?.error) {
      toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={`${submitResults.error.status} ${
            submitResults.error.message
          }- ${JSON.parse(submitResults.error.response?.text ?? {})?.message}`}
        />,
      );
    }
  }, [intl, submitResults, submitResults?.error, submitResults?.loaded]);

  const submit = () => {
    if (isValidForm()) {
      return dispatch(
        emailNotification(
          formValues.from,
          `Città: ${formValues.city}.\n\n${formValues.message}`,
          formValues.name,
          formValues.subject,
        ),
      );
    }
    return;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const isValidForm = () => {
    let errors = {};
    requiredFields.forEach((field) => {
      if (
        formValues[field] === null ||
        formValues[field] === undefined ||
        formValues[field]?.trim().length === 0
      ) {
        errors[field] = intl.formatMessage(messages.requiredField);
      }
    });

    if (formValues['from'] && !isValidEmail(formValues['from'])) {
      errors['from'] = intl.formatMessage(messages.emailNotValid);
    }

    setFormErrors(errors);

    return Object.keys(errors)?.length === 0;
  };

  const FieldErrorMessage = ({ field }) => {
    return formErrors[field] ? (
      <div className="contact-form-error">{formErrors[field]}</div>
    ) : (
      <></>
    );
  };

  const onChangeField = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const RAZZLE_RECAPTCHA_KEY = __CLIENT__
    ? window?.env?.RAZZLE_RECAPTCHA_KEY || process.env.RAZZLE_RECAPTCHA_KEY
    : process.env.RAZZLE_RECAPTCHA_KEY;

  return (
    <div className="contactform-block">
      <Form warning title={intl.formatMessage(messages.contactForm)}>
        <Form.Input
          fluid
          label={intl.formatMessage(messages.subject)}
          placeholder={intl.formatMessage(messages.subjectPlaceholder)}
          name="subject"
          id="contact-form-subject"
          value={formValues.subject}
          onChange={(e) => onChangeField('subject', e.target.value)}
          required={requiredFields.indexOf('subject') >= 0}
          title={intl.formatMessage(messages.subject)}
          aria-invalid={!!formErrors['subject']}
        />
        <FieldErrorMessage field="subject" />

        <Form.Input
          fluid
          label={intl.formatMessage(messages.name)}
          placeholder={intl.formatMessage(messages.name)}
          name="name"
          id="contact-form-name"
          value={formValues.name}
          onChange={(e) => onChangeField('name', e.target.value)}
          required={requiredFields.indexOf('name') >= 0}
          title={intl.formatMessage(messages.name)}
          aria-invalid={!!formErrors['name']}
        />
        <FieldErrorMessage field="name" />

        <Form.Input
          fluid
          type="email"
          label={intl.formatMessage(messages.emailAddress)}
          placeholder={intl.formatMessage(messages.email)}
          name="from"
          id="contact-form-from"
          value={formValues.from}
          onChange={(e) => onChangeField('from', e.target.value)}
          required={requiredFields.indexOf('from') >= 0}
          title={intl.formatMessage(messages.email)}
          aria-invalid={!!formErrors['from']}
        />
        <FieldErrorMessage field="from" />

        <Form.Input
          fluid
          label={`${intl.formatMessage(messages.city)} (${intl.formatMessage(
            messages.optional,
          )})`}
          placeholder={intl.formatMessage(messages.city)}
          name="city"
          id="contact-form-city"
          value={formValues.city}
          onChange={(e) => onChangeField('city', e.target.value)}
          required={requiredFields.indexOf('city') >= 0}
          aria-invalid={!!formErrors['city']}
        />
        <FieldErrorMessage field="city" />

        <Form.TextArea
          label={intl.formatMessage(messages.message)}
          placeholder={intl.formatMessage(messages.message)}
          name="message"
          id="contact-form-message"
          value={formValues.message}
          rows={3}
          onChange={(e) => onChangeField('message', e.target.value)}
          required={requiredFields.indexOf('message') >= 0}
          title={intl.formatMessage(messages.message)}
          aria-invalid={!!formErrors['message']}
        />
        <FieldErrorMessage field="message" />

        <Form.Checkbox
          label={{
            dangerouslySetInnerHTML: {
              __html: intl.formatMessage(messages.privacy),
            },
          }}
          name="privacyChecked"
          id="contact-form-privacychecked"
          checked={privacyChecked}
          onChange={(e) => setPrivacyChecked(e.target.checked)}
          required
        />

        {RAZZLE_RECAPTCHA_KEY && (
          <Form.Field inline>
            <GoogleReCaptchaProvider
              reCaptchaKey={RAZZLE_RECAPTCHA_KEY}
              language={intl.locale ?? 'it'}
            >
              <GoogleReCaptcha onVerify={setRecaptchaToken} />
            </GoogleReCaptchaProvider>
          </Form.Field>
        )}
        <Button
          floated="left"
          type="submit"
          disabled={
            !privacyChecked || (!recaptchaToken && RAZZLE_RECAPTCHA_KEY)
          }
          onClick={() => {
            submit();
          }}
        >
          {intl.formatMessage(messages.send)}
        </Button>
      </Form>
    </div>
  );
};

export default View;
