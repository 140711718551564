/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { VisitModenaHeader } from '@package/components/';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return <VisitModenaHeader pathname={this.props.pathname} />;
  }
}

export default Header;
