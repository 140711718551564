import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import {
  LinkToWidget,
  ImageSidebarWidget,
  ColorListWidget,
} from '@package/components';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

const messages = defineMessages({
  LinkSettings: {
    id: 'Link settings',
    defaultMessage: 'Impostazioni Link',
  },
  Settings: {
    id: 'Settings',
    defaultMessage: 'Impostazioni',
  },
  backgroundColor: {
    id: 'Background color',
    defaultMessage: 'Colore di sfondo',
  },
});

const Sidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
  onChangeSubblocks,
  selected,
  setSelected,
}) => {
  const backgroundColors = ['transparent', 'sidebarBackground'];

  return (
    <Segment.Group raised>
      <ImageSidebarWidget
        data={data}
        onChange={(data) => onChangeBlock(block, data)}
        openObjectBrowser={openObjectBrowser}
      />
      <Segment>
        <LinkToWidget
          data={data}
          openObjectBrowser={openObjectBrowser}
          onChange={(name, value) =>
            onChangeBlock(block, {
              ...data,
              [name]: value,
            })
          }
          showTarget={false}
        />

        <ColorListWidget
          id="backgroundColor"
          title={intl.formatMessage(messages.backgroundColor)}
          required={false}
          value={data.backgroundColor}
          colors={backgroundColors}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
      </Segment>

      {data.subblocks && (
        <Accordion fluid styled className="form">
          {data.subblocks.map((subblock, subblockIndex) => {
            let title =
              intl.formatMessage(messages.LinkSettings) +
              ' ' +
              (subblockIndex + 1);

            return (
              <div key={'subblock_' + subblockIndex}>
                <Accordion.Title
                  active={selected === subblockIndex}
                  index={subblockIndex}
                  onClick={() =>
                    setSelected(
                      selected === subblockIndex ? null : subblockIndex,
                    )
                  }
                >
                  {title}
                  {selected === subblockIndex ? (
                    <Icon name={upSVG} size="20px" />
                  ) : (
                    <Icon name={downSVG} size="20px" />
                  )}
                </Accordion.Title>
                <Accordion.Content active={selected === subblockIndex}>
                  <LinkToWidget
                    data={subblock}
                    openObjectBrowser={openObjectBrowser}
                    onChange={(name, value) =>
                      onChangeSubblocks(subblockIndex, {
                        ...subblock,
                        [name]: value,
                      })
                    }
                    showTarget={false}
                  />
                </Accordion.Content>
              </div>
            );
          })}
        </Accordion>
      )}
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
