/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { resetContent } from '@plone/volto/actions/content/content';
import { flattenToAppURL } from '@plone/volto/helpers';
import { srcset } from '@package/helpers';

const SmallHeaderFor = [
  'Esperienza',
  'SchedaTematica',
  'Event',
  'Itinerario',
  'StrutturaRicettiva',
  'Ristorante',
  'Storia',
];

const HideTitleFor = [
  'Esperienza',
  'SchedaTematica',
  'Event',
  'Itinerario',
  'StrutturaRicettiva',
  'Ristorante',
  'Storia',
];

const NoLeadImageFor = ['News Item'];
/**
 * LeadImage component class.
 * @class Header
 * @extends Component
 */
class LeadImage extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    getContent: PropTypes.func.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    content: {},
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (this.props.content) {
      const contentType = this.props.content['@type'];
      const showTitle = !(HideTitleFor.indexOf(contentType) >= 0);
      const image = this.props.content?.image?.scales?.leadimage;
      const videoUrl = this.props.content?.video?.download;
      const videoMimeType = this.props.content?.video?.['content-type'];

      let height = image?.height ?? 0;
      if (videoUrl) {
        height = 770;
      }
      if (SmallHeaderFor.indexOf(contentType) >= 0) {
        height = 250;
      }

      if (NoLeadImageFor.indexOf(contentType) >= 0) {
        return null;
      }

      return (
        <>
          {image || videoUrl ? (
            <div
              className={`lead-image ${videoUrl ? 'lead-video' : ''}`}
              id="leadimage-portal"
              style={{ height: height + 'px' }}
            >
              {videoUrl ? (
                <video
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  poster={
                    image?.download ? flattenToAppURL(image.download) : null
                  }
                >
                  <source type={videoMimeType} src={videoUrl} />
                </video>
              ) : (
                <>
                  <Image
                    src={flattenToAppURL(image.download)}
                    alt={this.props.content?.title ?? ''}
                    loading="lazy"
                    srcSet={srcset(
                      flattenToAppURL(image.download),
                      'leadimage',
                    )}
                  />
                  {this.props.content.title && showTitle && (
                    <h1>{this.props.content.title}</h1>
                  )}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      );
    }
    return <div id="leadimage-portal"></div>; // serve  per la pagina della wishlist e per il blocco slider
  }
}

export default connect(
  (state) => ({
    content: state.content.data,
    subrequests: state.content.subrequests,
  }),
  { getContent, resetContent },
)(LeadImage);
