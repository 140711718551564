import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Helmet } from '@plone/volto/helpers';
import TagManager from 'react-gtm-module';
import {
  usePanelConfigAndPreferences,
  GDPRCookies,
} from 'volto-gdpr-privacy/helpers';

const gtmCode = __CLIENT__
  ? window?.env?.RAZZLE_GTM_CODE || process.env.RAZZLE_GTM_CODE
  : process.env.RAZZLE_GTM_CODE;
const RAZZLE_GMAPS_API_KEY = __CLIENT__
  ? window?.env?.RAZZLE_GMAPS_API_KEY || process.env.RAZZLE_GMAPS_API_KEY
  : process.env.RAZZLE_GMAPS_API_KEY;

const GlobalAppExtras = ({ pathname }) => {
  const cookies = new GDPRCookies();
  const { defaultPreferences } = usePanelConfigAndPreferences(cookies);
  const gdprPreferences = useSelector(
    (state) => state.gdprPrivacyConsent.preferences ?? defaultPreferences,
  );

  useEffect(() => {
    if (
      __CLIENT__ &&
      gtmCode &&
      gdprPreferences?.MARKETING_GADS_FBPIXEL_MICROSOFT
    ) {
      TagManager.initialize({
        gtmId: gtmCode,
      });
    }
  }, [gdprPreferences?.MARKETING_GADS_FBPIXEL_MICROSOFT]);

  return (
    <>
      <Helmet titleTemplate={`%s - VisitModena`} />
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${RAZZLE_GMAPS_API_KEY}&libraries=places`}
        ></script>
        <meta property="og:type" content="website"></meta>
      </Helmet>
    </>
  );
};

export default connect(
  (state, props) => ({
    pathname: props.location?.pathname,
  }),
  {},
)(GlobalAppExtras);
