import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Input, Icon, Dropdown, Button } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { getSearchFiltersStructures } from '@package/actions';
import MapTemplate from '@package/components/Blocks/Listing/MapTemplate';
import { ListingImage } from '@package/components';

import {
  useInfiniteScroll,
  Booking,
  BookingExperience,
} from '@package/components';
import { motion, AnimatePresence } from 'framer-motion';

const messages = defineMessages({
  searchText: {
    id: 'struttura_ricettiva_search_text',
    defaultMessage: 'Digita la parola chiave',
  },
  clearText: {
    id: 'clear_text',
    defaultMessage: 'Cancella',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Caricamento in corso..',
  },
  all: {
    id: 'all',
    defaultMessage: 'Tutti',
  },
  results_found: { id: 'results_found', defaultMessage: 'risultati trovati' },
  no_results_found: {
    id: 'no_results_found',
    defaultMessage: 'Nessun risultato corrispondente ai criteri di ricerca',
  },
  search_name: {
    id: 'search_name',
    defaultMessage: 'Digita il nome',
  },
  search_restaurant_type: {
    id: 'search_restaurant_type',
    defaultMessage: 'Scegli il tipo',
  },

  search_services: {
    id: 'search_services',
    defaultMessage: 'Scegli i servizi',
  },
  search_accomodation_type: {
    id: 'search_accomodation_type',
    defaultMessage: 'Scegli il tipo',
  },
  search_municipality: {
    id: 'search_municipality',
    defaultMessage: 'Scegli il comune',
  },
  search_cuisine_type: {
    id: 'search_cuisine_type',
    defaultMessage: 'Scegli il tipo di cucina',
  },
  reset_search: {
    id: 'reset_search',
    defaultMessage: 'Annulla ricerca',
  },
  moreResults: {
    id: 'moreResults',
    defaultMessage:
      'Mancano ancora {results} risultati. Scrolla la pagina per continuare a caricarli.',
  },
  noMoreResults: {
    id: 'noMoreResults',
    defaultMessage: 'Non ci sono altri risultati',
  },
});

const FILTERS_CONFIG = {
  name: { type: 'text' },
  restaurant_type: { type: 'multiselect' },

  services: { type: 'multiselect' },
  accomodation_type: { type: 'multiselect' },

  cuisine_type: { type: 'multiselect' },
  municipality: { type: 'select' },
};
const SEARCH_TYPES_FILTERS = {
  Ristorante: ['name', 'restaurant_type', 'cuisine_type', 'municipality'], //    'services',
  StrutturaRicettiva: ['name', 'accomodation_type', 'municipality'], //'services',
};
/**
 * View class.
 * @class View
 * @extends Component
 */
const View = ({ data, isEditMode, state }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const searchStructures = useSelector((state) => {
    return state.searchFilters?.subrequests?.[data.search_type] || {};
  });

  const searchState = useSelector((state) => {
    return state.search;
  });
  const [filters, setFilters] = useState({});
  const [items, setItems] = useState([]);

  /***** Infinite scroll ******/
  const pageReducer = (state, action) => {
    switch (action.type) {
      case 'ADVANCE_PAGE':
        if (!searchState.loading) {
          if (searchState.total > items.length && items.length > 0) {
            //ci sono altri elementi da caricare
            return { ...state, page: state.page + 1 };
          }
        }
        return state;
      case 'RESET':
        return { ...state, page: 1 };
      default:
        return state;
    }
  };
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });
  let bottomBoundaryRef = useRef(null);
  useInfiniteScroll(bottomBoundaryRef, pagerDispatch);
  /***** end Infinite scroll *****/

  const doSearch = () => {
    if (Object.keys(filters).length === 0) {
      return;
    }
    const search_filters = Object.entries(filters).reduce(
      (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
      {},
    );
    delete search_filters.name;

    if (filters.name) {
      search_filters.SearchableText = filters.name + '*';
    }

    search_filters['Language'] = intl.locale;

    if (pager.page === 1) {
      setItems([]);
    }

    if (!searchState.loading) {
      const searchParam = {
        portal_type: data.search_type,
        // metadata_fields: [
        //   'title',
        //   'geolocation',
        //   'phone',
        //   'email',
        //   'website',
        //   'timetable',
        //   'UID',
        // ],
        fullobjects: true,
        ...search_filters,
        b_size: 5,
        b_start: (pager.page - 1) * 5, //(pager.page - 1) * config.settings.defaultPageSize,
        sort_on: 'random',
      };

      dispatch(searchContent('', searchParam));
    }
  };

  useEffect(() => {
    setItems(items.concat(searchState.items));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.items]);

  //get filters
  useEffect(() => {
    if (!searchStructures.loading && !searchStructures.loaded) {
      dispatch(getSearchFiltersStructures(data.search_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //clear filters if data (block props) changes
  useEffect(() => {
    setFilters({});
  }, [data]);

  //do search
  useEffect(() => {
    doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pager.page]);

  useEffect(() => {
    setItems([]);

    if (pager.page > 1) {
      pagerDispatch({ type: 'RESET' });
    } else {
      doSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterChange = (field, value) => {
    var f = { ...filters, [field]: value };
    if (typeof value === 'string' && value.length === 0) {
      delete f[field];
    }
    setFilters(f);
  };

  const drawFilters = () => {
    const filters_fields = SEARCH_TYPES_FILTERS[data.search_type];

    return (
      <div className={'site-search-filters search_type_' + data.search_type}>
        {filters_fields.map((field) => {
          switch (FILTERS_CONFIG[field].type) {
            case 'text':
              return (
                <div className="site-search-filter-text filter" key={field}>
                  <Input
                    placeholder={intl.formatMessage(
                      messages['search_' + field],
                    )}
                    aria-label={intl.formatMessage(messages['search_' + field])}
                    value={filters[field] || ''}
                    icon
                    onChange={(e, data) => {
                      filterChange(field, data.value);
                    }}
                  >
                    <input />
                    {filters[field]?.length > 0 && (
                      <Button
                        inverted
                        icon
                        title={intl.formatMessage(messages.clearText)}
                        aria-label={intl.formatMessage(messages.clearText)}
                        onClick={() => {
                          filterChange(field, '');
                        }}
                      >
                        <Icon name="times" />
                      </Button>
                    )}
                  </Input>
                </div>
              );
            case 'select':
              const select_values = searchStructures?.data[field] ?? [];
              return (
                <Dropdown
                  placeholder={intl.formatMessage(messages['search_' + field])}
                  aria-label={intl.formatMessage(messages['search_' + field])}
                  className="site-search-filter-select filter"
                  clearable
                  search
                  selection
                  options={[
                    ...select_values.map((f) => ({
                      key: f.value,
                      value: f.value,
                      text: f.label,
                    })),
                    {
                      key: 'all',
                      value: null,
                      text: intl.formatMessage(messages.all),
                    },
                  ]}
                  value={filters[field] || ''}
                  onChange={
                    (e, data) => {
                      filterChange(field, data.value);
                    }
                    // dispatchFilter({
                    //   type: filtersActions.SUBJECT,
                    //   value: data.value,
                    // })
                  }
                  key={field}
                />
              );

            case 'multiselect':
              const multiselect_values = searchStructures?.data[field] ?? [];
              return (
                <Dropdown
                  placeholder={intl.formatMessage(messages['search_' + field])}
                  aria-label={intl.formatMessage(messages['search_' + field])}
                  className="site-search-filter-select filter"
                  multiple
                  search
                  selection
                  options={multiselect_values?.map((f) => ({
                    key: f.value,
                    value: f.value,
                    text: f.label,
                  }))}
                  value={filters[field] || []}
                  onChange={(e, data) => {
                    filterChange(field, data.value);
                  }}
                  key={field}
                />
              );
            default:
              return null;
          }
        })}
        {/* <div className="site-search-filter-reset filter">
          <Button className="reset-filters" onClick={() => setFilters({})}>
            {intl.formatMessage(messages.reset_search)}
          </Button>
        </div> */}
      </div>
    );
  };

  return (
    <div className={data['@type']}>
      {searchStructures.data ? (
        data.search_type ? (
          drawFilters()
        ) : (
          <div>Seleziona un tipo di struttura</div>
        )
      ) : (
        <div className="loading">{intl.formatMessage(messages.loading)}</div>
      )}
      <div id="result-list">
        {items && items.length > 0 && (
          <>
            <MapTemplate
              items={items}
              isEditMode={isEditMode}
              addMarkerLink={false}
            />
            {filters && Object.keys(filters).length > 0 && (
              <div className="results-count">
                {searchState.total > 0
                  ? `${searchState.total} ${intl.formatMessage(
                      messages.results_found,
                    )}`
                  : intl.formatMessage(messages.no_results_found)}
              </div>
            )}

            <AnimatePresence initial={false}>
              <div className="items-list">
                {items.map((item) => (
                  <motion.div
                    key={item.UID}
                    className="item"
                    initial={{ scale: 0.85, opacity: 0.1 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.7, opacity: 0.2 }}
                  >
                    <ListingImage item={item} />

                    <div className="item-data">
                      <h3>{item.title}</h3>
                      {item.accomodation_type && (
                        <div className="type">
                          {item.accomodation_type.title}
                        </div>
                      )}

                      {item.restaurant_type && (
                        <div className="type">
                          {item.restaurant_type.map((t, i) => {
                            var sep =
                              i + 1 < item.restaurant_type.length ? ', ' : '';
                            return t.title + sep;
                          })}
                        </div>
                      )}
                      {item.cuisine_type && (
                        <div className="cuisine_type">
                          {item.cuisine_type.map((t, i) => {
                            var sep =
                              i + 1 < item.cuisine_type.length ? ', ' : '';
                            return t.title + sep;
                          })}
                        </div>
                      )}

                      {item.geolocation?.description && (
                        <p>{item.geolocation.description}</p>
                      )}
                      {item.phone && (
                        <p>
                          <Icon name="phone" />{' '}
                          <a href={'tel:' + item.phone}>{item.phone}</a>
                        </p>
                      )}
                      {item.email && (
                        <p>
                          <Icon name="mail" />
                          <a href={'mailto:' + item.email}>{item.email}</a>
                        </p>
                      )}

                      {item.website && (
                        <p>
                          <Icon name="world" />
                          <a
                            href={item.website}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.website}
                          </a>
                        </p>
                      )}

                      {item.timetable && (
                        <p>
                          <FormattedMessage
                            id="Opening dates and times"
                            defaultMessage="Date e orari di apertura"
                          />
                          : {item.timetable}
                        </p>
                      )}

                      <Booking content={item} intl={intl} />
                      <BookingExperience content={item} intl={intl} />
                    </div>
                  </motion.div>
                ))}
              </div>
            </AnimatePresence>
          </>
        )}
        <div id="page-bottom-boundary" ref={bottomBoundaryRef}></div>
        {searchState.total !== 0 && (
          <div className="results-count">
            {searchState.total - items.length > 0 ? (
              <span>
                {intl.formatMessage(messages.moreResults, {
                  results: searchState.total - items.length,
                })}
              </span>
            ) : (
              <span>{intl.formatMessage(messages.noMoreResults)}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
