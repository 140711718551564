import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { ConditionalLink } from '@plone/volto/components';

const messages = defineMessages({
  goToDetails: {
    id: 'map-go-to-details',
    defaultMessage: 'Vai al dettaglio',
  },
});

const MapMarker = ({
  venue,
  id,
  showTitle,
  showPrice,
  showPopup,
  mainMarker = false,
  addMarkerLink = false,
}) => {
  const intl = useIntl();
  const venue_id = `point_of_interest_${id}`;

  const marker = (
    <div
      role="button"
      tabindex="0"
      className={cx('map-marker', { 'main-marker': mainMarker })}
      onKeyDown={(event) => modalClick(event, venue_id)}
      aria-label={venue.title + ' ' + intl.formatMessage(messages.goToDetails)}
    >
      {(showTitle || showPrice) && (
        <ConditionalLink
          to={venue.link}
          condition={addMarkerLink && venue.link?.length > 0}
          className="marker-link"
        >
          <div className="map-marker-text">
            {showTitle && venue.title && <h2>{venue.title}</h2>}
            {showPrice && venue.price && <p>{venue.price}</p>}
          </div>
        </ConditionalLink>
      )}
      <Icon name="map marker alternate" />
    </div>
  );

  const modalClick = (event, venue_id) => {
    if (event.key === 'Enter') {
      document.getElementById(venue_id).click();
    }
  };

  return showPopup && venue.link ? (
    <Popup
      className="map-marker-popup"
      on={['click', 'focus']}
      trigger={marker}
      header={venue.title}
      content={
        <div>
          {showPrice && venue.price && (
            <p className="map-marker-price">{venue.price}</p>
          )}
          <p className="map-marker-link">
            <Link to={venue.link ?? '#'} id={venue_id}>
              {intl.formatMessage(messages.goToDetails)}
              <Icon name="chevron right" size="small" />
            </Link>
          </p>
        </div>
      }
      hideOnScroll
      hoverable
    />
  ) : (
    marker
  );
};

MapMarker.propTypes = {
  venue: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.string,
    link: PropTypes.string,
  }),
  showTitle: PropTypes.bool,
  showPrice: PropTypes.bool,
  showPopup: PropTypes.bool,
  mainMarker: PropTypes.bool,
  addMarkerLink: PropTypes.bool,
};

export default MapMarker;
