/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';

import { Container, Segment, Menu, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

import { LeadImage, HeaderWhishlist } from '~/components/';

import {
  /*Anontools,*/
  Logo,
  Navigation,
  SearchWidget,
  LanguageSelector,
} from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Cerca',
  },
});

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  state = { showSearch: false };

  componentDidMount() {
    window.addEventListener('scroll', this.handleSticky, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleSticky, false);
  }

  handleSticky = () => {
    this.setState({ sticky: window.scrollY > 100 });
  };

  toggleSearch = (ev) => {
    ev.preventDefault();
    this.setState(
      (state) => ({ showSearch: !state.showSearch }),
      () => {
        if (__CLIENT__ && this.state.showSearch) {
          let input = document?.querySelector(
            '.header-wrapper .search-wrapper input[type="text"]',
          );

          if (input) input.focus();
        }
      },
    );
  };

  onSubmitOrEsc = (e) => {
    if (this.state.showSearch) this.setState({ showSearch: false });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <>
        <Segment
          basic
          className={cx('header-wrapper', { sticky: this.state.sticky })}
          role="banner"
        >
          <Container className="no-w-margin-on-mobile">
            <div className="header">
              <div className="logo mobile hidden">
                <Logo />
              </div>
              <LanguageSelector className="navigation language-selector mobile only" />
              <div className="navigation-block no-w-margin-on-mobile">
                <div className="payoff mobile hidden">
                  <FormattedMessage
                    id="SitePayoff"
                    defaultMessage="Sito Ufficiale di Informazione Turistica di Modena"
                  />
                </div>
                <Navigation pathname={this.props.pathname} />
                <div className="logo mobile only">
                  <Logo />
                  <div className="payoff-mobile">
                    <FormattedMessage
                      id="SitePayoff"
                      defaultMessage="Sito Ufficiale di Informazione Turistica di Modena"
                    />
                  </div>
                </div>
                <div className="right-links">
                  <LanguageSelector className="navigation language-selector tablet computer large screen widescreen only" />

                  <div className="navigation">
                    <Menu pointing secondary onClick={this.closeMobileMenu}>
                      <Menu.Item className="whishlistItem">
                        <HeaderWhishlist />
                      </Menu.Item>
                      <Menu.Item className="searchButton">
                        <button
                          key="searchbtn"
                          title={this.props.intl.formatMessage(messages.search)}
                          onClick={this.toggleSearch}
                        >
                          <Icon name="search" />
                        </button>
                      </Menu.Item>
                    </Menu>
                  </div>
                  {/* <div className="navigation">
                    <Menu pointing secondary onClick={this.closeMobileMenu}>
                      <Link
                        to="/it"
                        key="it"
                        className="item tablet computer large screen widescreen only"
                      >
                        IT
                      </Link>
                      <Link
                        to="/en"
                        key="en"
                        className="item tablet computer large screen widescreen only"
                      >
                        EN
                      </Link>
                      <button
                        key="searchbtn"
                        className="item searchButton"
                        onClick={this.toggleSearch}
                      >
                        <Icon name="search" />
                      </button>
                    </Menu>
                  </div>*/}
                </div>
              </div>
              {/*!this.props.token && (
                <div className="tools">
                  <Anontools />
                </div>
              )*/}
            </div>

            {this.state.showSearch ? (
              <OutsideClickHandler
                onOutsideClick={() => this.setState({ showSearch: false })}
              >
                <Container className="search-wrapper">
                  <SearchWidget
                    pathname={this.props.pathname}
                    onSubmitOrEsc={this.onSubmitOrEsc}
                  />
                </Container>
              </OutsideClickHandler>
            ) : null}
          </Container>
        </Segment>
        <LeadImage pathname={this.props.pathname} />
      </>
    );
  }
}

export default compose(
  injectIntl,
  connect((state) => ({
    token: state.userSession.token,
  })),
)(Header);
