/**
 * ViewBlock.
 * @module components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Grid, Icon } from 'semantic-ui-react';
import { GoogleMap } from '@package/components';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  contact_data: {
    id: 'ContactBox_contactData',
    defaultMessage: 'Dati di contatto',
  },
  contact_timetable: {
    id: 'ContactBox_contactTimetable',
    defaultMessage: 'Orari di apertura',
  },
  where: {
    id: 'ContactBox_doveSiamo',
    defaultMessage: 'Dove siamo',
  },
});

/**
 * ViewBlock class.
 * @class ViewBlock
 * @extends Component
 */
const ViewBlock = ({ data, nblock, intl }) => {
  const contact = (data.phone ||
    data.fax ||
    data.mail ||
    data.address | data.website) && (
    <>
      <div className="info-box-header">
        {intl.formatMessage(messages.contact_data)}
      </div>
      <div className="info-box-text">
        {data.phone && (
          <div className="info">
            <Icon name="phone" />
            <UniversalLink href={'tel:' + data.phone}>
              {data.phone}
            </UniversalLink>
          </div>
        )}
        {data.fax && (
          <div className="info">
            <Icon name="fax" />
            {data.fax}
          </div>
        )}
        {data.mail && (
          <div className="info">
            <Icon name="mail" />
            <UniversalLink href={'mailto:' + data.mail}>
              {data.mail}
            </UniversalLink>
          </div>
        )}
        {data.address && (
          <div className="info">
            <Icon name="map marker alternate" />
            <UniversalLink
              href={'https://www.google.it/maps/search/' + data.address}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.address}
            </UniversalLink>
          </div>
        )}
        {data.website && (
          <div className="info">
            <Icon name="world" />
            <UniversalLink href="/" target="_blank" rel="noopener noreferrer">
              {data.website}
            </UniversalLink>
          </div>
        )}
      </div>
    </>
  );

  const timetable = data.timetable && (
    <>
      <div className="info-box-header">
        {intl.formatMessage(messages.contact_timetable)}
      </div>
      <div className="info-box-text">{data.timetable}</div>
    </>
  );

  let content = contact;
  if (timetable) {
    content = (
      <Grid stackable columns={nblock < 3 ? 2 : 1}>
        <Grid.Column>{contact}</Grid.Column>
        <Grid.Column>{timetable}</Grid.Column>
      </Grid>
    );
  }

  content = <div className="info-box">{content}</div>;

  if (data.geolocation && data.geolocation.lat) {
    content = (
      <Grid stackable>
        <Grid.Column computer={nblock === 1 ? 8 : 12}>{content}</Grid.Column>
        <Grid.Column computer={nblock === 1 ? 4 : 12} className="geolocation">
          <div className="info-box">
            <div className="info-box-header">
              {intl.formatMessage(messages.where)}
            </div>
            <div className="info-box-content">
              <GoogleMap
                showPopup={false}
                height="300px"
                venues={[
                  {
                    lat: parseFloat(data.geolocation.lat),
                    lng: parseFloat(data.geolocation.lng),
                  },
                ]}
              />
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <div className={`single-block contact nblock-${nblock}`}>
      <h3 className="contact-title">{data.title}</h3>
      {content}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(ViewBlock);
