import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from '@plone/volto/components';
import { useWhishlist } from '@package/helpers';
import { Label } from 'semantic-ui-react';
import HeartEmpty from '@package/icons/heart-regular.svg';
import HeartFull from '@package/icons/heart-solid.svg';

const messages = defineMessages({
  go_to_whishlist: {
    id: 'Vai alla whishlist',
    defaultMessage: 'Vedi la tua lista dei desideri',
  },
});
const HeaderWhishlist = ({ pathname }) => {
  const intl = useIntl();
  const { whishlist = [] } = useWhishlist();

  return (
    <div className="header-whishlist">
      {__CLIENT__ && (
        <UniversalLink
          href={'/' + intl.locale + '/whishlist'}
          title={intl.formatMessage(messages.go_to_whishlist)}
        >
          {whishlist.length > 0 && (
            <Label floating color="red">
              {whishlist.length}
            </Label>
          )}
          <Icon name={whishlist.length > 0 ? HeartFull : HeartEmpty} />
        </UniversalLink>
      )}
    </div>
  );
};

export default HeaderWhishlist;
