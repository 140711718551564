/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';

import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';
import { Helmet, langmap, flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from 'semantic-ui-react';

import config from '@plone/volto/registry';
import {
  Select,
  Button,
  Item,
  ListBox,
  Popover,
  Label,
} from 'react-aria-components';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Site language',
    defaultMessage: 'Site language: {currentLang}',
  },
});

const LanguageSelector = (props) => {
  const history = useHistory();
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;

  //Sistema il bug per la scrollbar laterale (https://github.com/adobe/react-sp ectrum/issues/1216)
  const handleOpenChange = (isOpen) => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  };

  //PEr gestire "Portals are not currently supported by the server renderer"
  if (__SERVER__) {
    return <div />;
  }

  return settings.isMultilingual ? (
    <div>
      <Select
        className={props.className}
        onSelectionChange={(id) => {
          const translation = find(translations, { language: id });
          history.push(
            translation ? flattenToAppURL(translation['@id']) : `/${id}`,
          );
        }}
        onOpenChange={handleOpenChange}
        defaultSelectedKey={currentLang}
      >
        <Label>
          {intl.formatMessage(messages.switchLanguageTo, { currentLang })}
        </Label>
        <Button>
          <span>{currentLang}</span>
          <Icon name="caret down" />
        </Button>
        <Popover>
          <ListBox>
            {map(settings.supportedLanguages, (lang) => {
              return (
                <Item
                  className={cx({ selected: lang === currentLang })}
                  id={lang}
                  isSelected
                  key={`language-selector-${langmap[lang].nativeName}`}
                >
                  {langmap[lang].nativeName}
                </Item>
              );
            })}
          </ListBox>
        </Popover>
      </Select>
    </div>
  ) : (
    <Helmet>
      <html lang={config.settings.defaultLanguage} />
    </Helmet>
  );
};

export default LanguageSelector;
