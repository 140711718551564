import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ConditionalLink } from '@plone/volto/components';

import TagsView from './TagsView';

import { PriceRangeIndications, ListingImage } from '@package/components';

const DefaultItem = ({ item, isEditMode }) => {
  return (
    <div className="item default-item">
      <div className="item-title">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
        >
          <h3>{item.title ? item.title : item.id}</h3>
        </ConditionalLink>
      </div>

      <div className="item-border" />

      <div className="item-content">
        <ConditionalLink
          to={flattenToAppURL(item['@id'])}
          condition={!isEditMode}
          aria-hidden="true"
          role="presentation"
          tabindex="-1"
        >
          {item.thematic_area && (
            <TagsView subjects={[item.thematic_area.title]} />
          )}

          <ListingImage item={item} />

          <PriceRangeIndications item={item} />
        </ConditionalLink>
      </div>
    </div>
  );
};

DefaultItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DefaultItem;
