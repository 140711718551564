import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Accordion } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import redraft from 'redraft';
import { LinkToWidget, ColorListWidget } from '@package/components';
import { Icon } from '@plone/volto/components';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import config from '@plone/volto/registry';

const messages = defineMessages({
  backgroundColor: {
    id: 'Background color',
    defaultMessage: 'Colore di sfondo',
  },
});
const Sidebar = ({
  data,
  block,
  onChangeBlock,
  onChangeSubblock,
  openObjectBrowser,
  required = false,
  selected = 0,
  setSelected,
  intl,
}) => {
  const backgroundColors = ['transparent', 'sidebarBackground'];

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="ImageBlock"
            defaultMessage="Blocchi con immagine"
          />
          {data.title && ': '}
          {data.title
            ? redraft(
                data.title,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )
            : ''}
        </h2>
      </header>
      <Segment>
        <ColorListWidget
          id="backgroundColor"
          title={intl.formatMessage(messages.backgroundColor)}
          required={false}
          value={data.backgroundColor}
          colors={backgroundColors}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
      </Segment>

      <Accordion fluid styled className="form">
        {data.subblocks &&
          data.subblocks.map((subblock, index) => (
            <div key={'subblock' + index}>
              <Accordion.Title
                active={selected === index}
                index={index}
                onClick={setSelected}
              >
                {subblock.title
                  ? redraft(
                      subblock.title,
                      config.settings.richtextViewSettings.ToHTMLRenderers,
                      config.settings.richtextViewSettings.ToHTMLOptions,
                    )
                  : 'Blocco ' + (index + 1)}
                {selected === index ? (
                  <Icon name={upSVG} size="20px" />
                ) : (
                  <Icon name={downSVG} size="20px" />
                )}
              </Accordion.Title>
              <Accordion.Content active={selected === index}>
                <LinkToWidget
                  data={subblock}
                  openObjectBrowser={openObjectBrowser}
                  onChange={(name, value) => {
                    onChangeSubblock(index, {
                      ...subblock,
                      [name]: value,
                    });
                  }}
                />
              </Accordion.Content>
            </div>
          ))}
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  onChangeSubblock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
