/**
 * View cta box block.
 * @module components/Blocks/CtaBox/View
 */

import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import redraft from 'redraft';
import { Grid } from 'semantic-ui-react';
import { ButtonLink, Box } from '~/components';
import ViewIcon from './Icon/ViewIcon';

import config from '@plone/volto/registry';
/**
 * View cta box block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached }) => {
  const text =
    data.title ||
    (data.description && data.description.text?.blocks[0]?.text) ||
    data.href ? (
      <Box className={cx('ctaBox', 'icons-' + data.iconsPosition ?? 'bottom')}>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={data.iconsPosition === 'bottom' ? 12 : 7}>
              {data.title ? <div className="title">{data.title}</div> : null}
              {data.description && data.description.text ? (
                <div className="description">
                  {' '}
                  {redraft(
                    data.description.text,
                    config.settings.richtextViewSettings.ToHTMLRenderers,
                    config.settings.richtextViewSettings.ToHTMLOptions,
                  )}
                </div>
              ) : null}
            </Grid.Column>
            <Grid.Column
              width={data.iconsPosition === 'bottom' ? 12 : 5}
              verticalAlign="middle"
            >
              {data.subblocks ? (
                <Grid
                  className="icons"
                  stackable
                  columns="equal"
                  verticalAlign="middle"
                  centered
                >
                  <Grid.Row>
                    {data.subblocks.map((icon, iconindex) => (
                      <Grid.Column
                        key={icon.id}
                        width={
                          data.iconsPosition === 'bottom'
                            ? data.subblocks.length > 1
                              ? 2
                              : 10
                            : data.subblocks.length > 1
                            ? 4
                            : 10
                        }
                      >
                        <ViewIcon data={icon} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          {data.href && (
            <Grid.Row>
              <Grid.Column textAlign="center">
                <ButtonLink
                  to={data.href}
                  title={data.linkTitle}
                  targetBlank={data.openLinkInNewTab}
                  color="red"
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Box>
    ) : null;

  return text;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
