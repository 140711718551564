/**
 * View image block.
 * @module components/Blocks/FullImage/View
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Container } from 'semantic-ui-react';
import { ButtonLink, ImageFromUrl } from '~/components';
import { useIntl, defineMessages } from 'react-intl';
import { Icon } from 'semantic-ui-react';

const messages = defineMessages({
  playVideo: {
    id: 'Play Video',
    defaultMessage: 'Play Video',
  },
  pauseVideo: {
    id: 'Pause Video',
    defaultMessage: 'Pause Video',
  },
});

/**
 * View image block class.
 * @class View
 * @extends Component
 */

const View = ({ data, detached, id }) => {
  const intl = useIntl();
  const [userAutoplay, seUserAutoplay] = useState(true);

  const togleAutoplay = () => {
    if (userAutoplay) {
      document.getElementById(data.video[0].UID + id).pause();
      seUserAutoplay(false);
    } else {
      document.getElementById(data.video[0].UID + id).play();
      seUserAutoplay(true);
    }
  };

  const overlayText =
    data.title || data.description || data.href ? (
      <div className="overlay-text">
        <Container>
          {data.title ? <div className="title">{data.title}</div> : null}
          {data.description ? (
            <div className="description mobile hidden">{data.description}</div>
          ) : null}

          <ButtonLink
            to={data.href}
            title={data.linkTitle}
            targetBlank={data.openLinkInNewTab}
            color={data.buttonLinkColor}
            showArrow={data.showArrowOnLink}
            _className={data.buttonLinkAlign}
          />
        </Container>
      </div>
    ) : null;

  const videoPoster =
    data.video_poster?.[0]?.image?.scales?.leadimage?.download;
  const video = data.video?.[0];
  const videoUrl = video
    ? video.file?.download ?? video.getURL + '/@@download/file'
    : null;
  const videoMimeType = video?.file?.['content-type'] ?? video?.mime_type;

  return (
    <div
      className={cx('block fullImage', {
        detached,
      })}
    >
      {(data.url || data.video) && (
        <div
          className={cx(
            {
              'full-width': data.align === 'full',
              'bg-video': data.bgType === 'video',
            },
            data.size,
          )}
        >
          {data.bgType === 'video' ? (
            <>
              {/*----------------VIDEO----------------*/}
              {videoUrl && (
                <video
                  id={data.video[0].UID + id}
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  poster={videoPoster}
                >
                  <source type={videoMimeType} src={videoUrl} />
                </video>
              )}
            </>
          ) : (
            <>
              {/*----------------IMMAGINE----------------*/}
              {data.url && (
                <ImageFromUrl
                  url={data.url}
                  className="bg"
                  title={data.alt || ''}
                  size="leadimage"
                />
              )}
            </>
          )}

          {/*----------------OVERLAY TEXT----------------*/}
          {overlayText}
          {videoUrl && (
            <div className="play-pause-wrapper">
              <button
                onClick={() => togleAutoplay()}
                title={
                  userAutoplay
                    ? intl.formatMessage(messages.pauseVideo)
                    : intl.formatMessage(messages.playVideo)
                }
              >
                <Icon name={userAutoplay ? 'pause' : 'play'} />
                <span>{userAutoplay ? 'pause' : 'play'}</span>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
};

export default View;
