import React from 'react';
import { compose } from 'redux';
import { injectIntl, defineMessages } from 'react-intl';
import { GeoLocationWidget } from '@package/components';
import { DNDSubblocks, SubblockEdit, Subblock } from 'volto-subblocks';
import { Grid, Icon } from 'semantic-ui-react';
import { TextAreaWidget } from '@package/components';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'Title placeholder',
    defaultMessage: 'Title...',
  },
  phonePlaceholder: {
    id: 'Phone placeholder',
    defaultMessage: 'Tel',
  },
  faxPlaceholder: {
    id: 'Fax placeholder',
    defaultMessage: 'Fax',
  },
  mailPlaceholder: {
    id: 'Mail placeholder',
    defaultMessage: 'E-mail',
  },
  addressPlaceholder: {
    id: 'Address placeholder',
    defaultMessage: 'Indirizzo',
  },
  websitePlaceholder: {
    id: 'Website placeholder',
    defaultMessage: 'Sito web',
  },
  timetablePlaceholder: {
    id: 'Timetable placeholder',
    defaultMessage: 'Orari di apertura',
  },
  contact_data: {
    id: 'ContactBox_contactData',
    defaultMessage: 'Dati di contatto',
  },
  contact_timetable: {
    id: 'ContactBox_contactTimetable',
    defaultMessage: 'Orari di apertura',
  },
  where: {
    id: 'ContactBox_doveSiamo',
    defaultMessage: 'Dove siamo',
  },
});

/**
 * Edit Contact block class.
 * @class Edit
 * @extends Component
 */
class EditBlock extends SubblockEdit {
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <Subblock subblock={this} className="contact">
        <h3 className="contact-title">
          <TextAreaWidget
            name="title"
            value={this.props.data.title}
            placeholder={this.props.intl.formatMessage(
              messages.titlePlaceholder,
            )}
            onChange={this.onChange}
          />
        </h3>
        <Grid stackable>
          <Grid.Column computer={this.props.nblock === 1 ? 8 : 12} tablet={12}>
            <div className="info-box">
              <Grid stackable columns={this.props.nblock < 3 ? 2 : 1}>
                <Grid.Column>
                  <div className="info-box-header">
                    {this.props.intl.formatMessage(messages.contact_data)}
                  </div>
                  <div className="info-box-text">
                    <div className="info">
                      <Icon name="phone" />

                      <TextAreaWidget
                        name="phone"
                        value={this.props.data.phone}
                        placeholder={this.props.intl.formatMessage(
                          messages.phonePlaceholder,
                        )}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="info">
                      <Icon name="fax" />
                      <TextAreaWidget
                        name="fax"
                        value={this.props.data.fax}
                        placeholder={this.props.intl.formatMessage(
                          messages.faxPlaceholder,
                        )}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="info">
                      <Icon name="mail" />
                      <TextAreaWidget
                        name="mail"
                        value={this.props.data.mail}
                        placeholder={this.props.intl.formatMessage(
                          messages.mailPlaceholder,
                        )}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="info">
                      <Icon name="map marker alternate" />
                      <TextAreaWidget
                        name="address"
                        value={this.props.data.address}
                        placeholder={this.props.intl.formatMessage(
                          messages.addressPlaceholder,
                        )}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="info">
                      <Icon name="world" />
                      <TextAreaWidget
                        name="website"
                        value={this.props.data.website}
                        placeholder={this.props.intl.formatMessage(
                          messages.websitePlaceholder,
                        )}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="info-box-header">
                    {this.props.intl.formatMessage(messages.contact_timetable)}
                  </div>
                  <div className="info-box-text">
                    <TextAreaWidget
                      name="timetable"
                      value={this.props.data.timetable}
                      placeholder={this.props.intl.formatMessage(
                        messages.timetablePlaceholder,
                      )}
                      onChange={this.onChange}
                      rows={4}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </Grid.Column>
          <Grid.Column
            className="geolocation"
            computer={this.props.nblock === 1 ? 4 : 12}
            tablet={12}
          >
            <div className="info-box">
              <div className="info-box-header">
                {this.props.intl.formatMessage(messages.where)}
              </div>
              <div className="info-box-content">
                <GeoLocationWidget
                  value={this.props.data.geolocation}
                  id="geolocation"
                  onChange={(id, value) => {
                    this.onChange({
                      [id]: value,
                    });
                  }}
                  intl={this.props.intl}
                  title={' '}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Subblock>
    );
  }
}

export default React.memo(compose(injectIntl, ...DNDSubblocks)(EditBlock));
